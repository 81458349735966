import React, { useCallback, useEffect, useState } from 'react';
import taiwanHeader from './resources/images/taiwan-header.png';
import graph from './resources/images/graph.jpg';
import './App.css';
import moment from 'moment';
import { PeriodType } from './mytype/period';
import { services } from './services/services';
import { utils } from './utils/utils';

const announceDate = moment().format('YYYY-MM-DD 12:30:00'); // 12.20 close

const App = () => {

  const [taiwanStat, setTaiwanStat] = useState<PeriodType[]>([]);
  const [taiwanCurrent, setTaiwanCurrent] = useState<PeriodType | null>(null);
  const [positiveOrNegative, setPositiveOrNegative] = useState("+");
  const [positiveOrNegativeChange, setPositiveOrNegativeChange] = useState("+");
  const [positiveOrNegativePercent, setPositiveOrNegativePercent] = useState("+");
  const [upNumber, setUpNumber] = useState(24000.00);
  const [downNumber, setDownNumber] = useState(0.00);
  const [changedPercent, setChangedPercent] = useState(0.00);
  const [volumn10Mil, setVolumn10Mil] = useState(0.00);

  const [twIndex50, setTwIndex50] = useState({
    value: 17000.00,
    change: 0.00,
    changePercent: 0.00,
    positiveOrNegative: "+",
    positiveOrNegativeChange: "+",
    positiveOrNegativePercent: "+"
  });

  const [cgIndex100, setCgIndex100] = useState({
    value: 15000.00,
    change: 0.00,
    changePercent: 0.00,
    positiveOrNegative: "+",
    positiveOrNegativeChange: "+",
    positiveOrNegativePercent: "+"
  });

  const getTaiwanStatData = useCallback(async () => {
    const response = await services.getStatistics(20);
    setTaiwanStat(response.data);
  }, []);

  useEffect(() => {

    getTaiwanStatData();
    taiwanData();

    // every 5 min
    triggerData();
    tw50cg100Data();
    const interval = setInterval(() => {
      triggerData();
    }, 30000);

    const intervalForShow = setInterval(() => {
      if (moment().toDate().getTime() === moment(announceDate).toDate().getTime()) {
        triggerData();
      }
    }, (60 * 1000));

    return () => {
      clearInterval(interval);
      clearInterval(intervalForShow)
    }
  }, [getTaiwanStatData]);

  const triggerData = async () => {

    let positiveOrNegativeNumber = Math.round(Math.random());
    if (positiveOrNegativeNumber) positiveOrNegativeNumber = 1.00;
    else positiveOrNegativeNumber = -1.00;

    let positiveOrNegativeNumberLast = Math.round(Math.random());
    if (positiveOrNegativeNumberLast) positiveOrNegativeNumberLast = 1.00;
    else positiveOrNegativeNumberLast = -1.00;

    let positiveOrNegativeNumberChange = Math.round(Math.random());
    if (positiveOrNegativeNumberChange) positiveOrNegativeNumberChange = 1.00;
    else positiveOrNegativeNumberChange = -1.00;

    let positiveOrNegativeNumberPercent = Math.round(Math.random());
    if (positiveOrNegativeNumberPercent) positiveOrNegativeNumberPercent = 1.00;
    else positiveOrNegativeNumberPercent = -1.00;

    if (moment().toDate().getTime() >= moment(announceDate).toDate().getTime()) {
      // show release
      if (!taiwanCurrent) {
        const currentTaiwan = await services.getCurrentPeriods(20);
        const data: PeriodType = currentTaiwan.data;
        const num = parseInt(upNumber.toString().substring(0, 4) + data.up3) * 0.01;

        const precisionDown = 100; // 2 decimals
        const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

        const precisionChangedPercent = 100; // 2 decimals
        const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

        const precisionVolumn10Bil = 100; // 2 decimals
        const numVolumn10Bil = Math.floor(Math.random() * (100 * precisionVolumn10Bil - 1 * precisionVolumn10Bil) + 1 * precisionVolumn10Bil) / (1 * precisionVolumn10Bil);

        setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (data.down2)?.toString()) * (positiveOrNegativeNumber));
        setChangedPercent(numChangedPercent * (positiveOrNegativeNumber));
        setUpNumber((num * (positiveOrNegative === "+" ? 1.00 : -1.00)));
        setVolumn10Mil(numVolumn10Bil);
        setPositiveOrNegative(positiveOrNegativeNumber === 1.00 ? "+" : "-");
        setPositiveOrNegativeChange(positiveOrNegativeNumberChange === 1.00 ? "+" : "-");
        setPositiveOrNegativePercent(positiveOrNegativeNumberPercent === 1.00 ? "+" : "-");
        return setTaiwanCurrent(currentTaiwan.data);
      }

      const num = parseInt(upNumber.toString().substring(0, 4) + taiwanCurrent.up3) * 0.01;

      const precisionDown = 100; // 2 decimals
      const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

      const precisionChangedPercent = 100; // 2 decimals
      const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

      const precisionVolumn10Bil = 100; // 2 decimals
      const numVolumn10Bil = Math.floor(Math.random() * (100 * precisionVolumn10Bil - 1 * precisionVolumn10Bil) + 1 * precisionVolumn10Bil) / (1 * precisionVolumn10Bil);

      setDownNumber(parseFloat(numDown.toString().split(".")[0] + "." + (taiwanCurrent.down2)?.toString()) * (positiveOrNegativeNumber));
      setChangedPercent(numChangedPercent * (positiveOrNegativeNumber));
      setUpNumber((num * (positiveOrNegativeNumber)));
      setVolumn10Mil(numVolumn10Bil);
      setPositiveOrNegative(positiveOrNegativeNumber === 1.00 ? "+" : "-");
      setPositiveOrNegativeChange(positiveOrNegativeNumberChange === 1.00 ? "+" : "-");
      setPositiveOrNegativePercent(positiveOrNegativeNumberPercent === 1.00 ? "+" : "-");
      return;
    }

    if (moment().toDate().getTime() < moment(announceDate).toDate().getTime()) {
      return randomNumber();
    }

  }

  const randomNumber = () => {

    let positiveOrNegativeNumber = Math.round(Math.random());
    if (positiveOrNegativeNumber) positiveOrNegativeNumber = 1.00;
    else positiveOrNegativeNumber = -1.00;

    let positiveOrNegativeNumberLast = Math.round(Math.random());
    if (positiveOrNegativeNumberLast) positiveOrNegativeNumberLast = 1.00;
    else positiveOrNegativeNumberLast = -1.00;

    let positiveOrNegativeNumberChange = Math.round(Math.random());
    if (positiveOrNegativeNumberChange) positiveOrNegativeNumberChange = 1.00;
    else positiveOrNegativeNumberChange = -1.00;

    let positiveOrNegativeNumberPercent = Math.round(Math.random());
    if (positiveOrNegativeNumberPercent) positiveOrNegativeNumberPercent = 1.00;
    else positiveOrNegativeNumberPercent = -1.00;

    const precisionUp = 100; // 2 decimals
    const numUp = Math.floor(Math.random() * (100 * precisionUp - 1 * precisionUp) + 1 * precisionUp) / (1 * precisionUp);

    const precisionDown = 100; // 2 decimals
    const numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

    const precisionChangedPercent = 100; // 2 decimals
    const numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

    const precisionVolumn10Bil = 100; // 2 decimals
    const numVolumn10Bil = Math.floor(Math.random() * (100 * precisionVolumn10Bil - 1 * precisionVolumn10Bil) + 1 * precisionVolumn10Bil) / (1 * precisionVolumn10Bil);

    // const precisionVolumn100Bil = 100; // 2 decimals
    // const numVolumn100Bil = Math.floor(Math.random() * (10000 * precisionVolumn100Bil - 1 * precisionVolumn100Bil) + 1 * precisionVolumn100Bil) / (1 * precisionVolumn100Bil);


    setUpNumber(upNumber + (numUp * positiveOrNegativeNumber));
    setDownNumber(numDown * positiveOrNegativeNumberChange);
    setChangedPercent(numChangedPercent * positiveOrNegativeNumberPercent);
    setVolumn10Mil(numVolumn10Bil);
    // setVolumn100Bil(numVolumn100Bil);
    setPositiveOrNegative(positiveOrNegativeNumber === 1.00 ? "+" : "-");
    setPositiveOrNegativeChange(positiveOrNegativeNumberChange === 1.00 ? "+" : "-");
    setPositiveOrNegativePercent(positiveOrNegativeNumberPercent === 1.00 ? "+" : "-");
  }

  const tw50cg100Data = () => {
    // tw50
    let positiveOrNegativeNumber = Math.round(Math.random());
    if (positiveOrNegativeNumber) positiveOrNegativeNumber = 1.00;
    else positiveOrNegativeNumber = -1.00;

    let positiveOrNegativeNumberLast = Math.round(Math.random());
    if (positiveOrNegativeNumberLast) positiveOrNegativeNumberLast = 1.00;
    else positiveOrNegativeNumberLast = -1.00;

    let positiveOrNegativeNumberChange = Math.round(Math.random());
    if (positiveOrNegativeNumberChange) positiveOrNegativeNumberChange = 1.00;
    else positiveOrNegativeNumberChange = -1.00;

    let positiveOrNegativeNumberPercent = Math.round(Math.random());
    if (positiveOrNegativeNumberPercent) positiveOrNegativeNumberPercent = 1.00;
    else positiveOrNegativeNumberPercent = -1.00;

    const precisionUp = 100; // 2 decimals
    let numUp = Math.floor(Math.random() * (100 * precisionUp - 1 * precisionUp) + 1 * precisionUp) / (1 * precisionUp);

    const precisionDown = 100; // 2 decimals
    let numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

    const precisionChangedPercent = 100; // 2 decimals
    let numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

    const precisionVolumn10Bil = 100; // 2 decimals
    let numVolumn10Bil = Math.floor(Math.random() * (100 * precisionVolumn10Bil - 1 * precisionVolumn10Bil) + 1 * precisionVolumn10Bil) / (1 * precisionVolumn10Bil);

    const resTw50 = {
      value: twIndex50.value + (numUp * positiveOrNegativeNumber),
      change: numDown * positiveOrNegativeNumberChange,
      changePercent: numChangedPercent * positiveOrNegativeNumberPercent,
      volumn10Mil: numVolumn10Bil,
      positiveOrNegative: positiveOrNegativeNumber === 1.00 ? "+" : "-",
      positiveOrNegativeChange: positiveOrNegativeNumberChange === 1.00 ? "+" : "-",
      positiveOrNegativePercent: positiveOrNegativeNumberPercent === 1.00 ? "+" : "-"
    }

    positiveOrNegativeNumber = Math.round(Math.random());
    if (positiveOrNegativeNumber) positiveOrNegativeNumber = 1.00;
    else positiveOrNegativeNumber = -1.00;

    positiveOrNegativeNumberLast = Math.round(Math.random());
    if (positiveOrNegativeNumberLast) positiveOrNegativeNumberLast = 1.00;
    else positiveOrNegativeNumberLast = -1.00;

    positiveOrNegativeNumberChange = Math.round(Math.random());
    if (positiveOrNegativeNumberChange) positiveOrNegativeNumberChange = 1.00;
    else positiveOrNegativeNumberChange = -1.00;

    positiveOrNegativeNumberPercent = Math.round(Math.random());
    if (positiveOrNegativeNumberPercent) positiveOrNegativeNumberPercent = 1.00;
    else positiveOrNegativeNumberPercent = -1.00;

    // cg100
    numUp = Math.floor(Math.random() * (100 * precisionUp - 1 * precisionUp) + 1 * precisionUp) / (1 * precisionUp);

    numDown = Math.floor(Math.random() * (100 * precisionDown - 1 * precisionDown) + 1 * precisionDown) / (1 * precisionDown);

    numChangedPercent = Math.floor(Math.random() * (10 * precisionChangedPercent - 1 * precisionChangedPercent) + 1 * precisionChangedPercent) / (1 * precisionChangedPercent);

    numVolumn10Bil = Math.floor(Math.random() * (100 * precisionVolumn10Bil - 1 * precisionVolumn10Bil) + 1 * precisionVolumn10Bil) / (1 * precisionVolumn10Bil);

    const resCg100 = {
      value: cgIndex100.value + (numUp * positiveOrNegativeNumber),
      change: numDown * positiveOrNegativeNumberChange,
      changePercent: numChangedPercent * positiveOrNegativeNumberPercent,
      volumn10Mil: numVolumn10Bil,
      positiveOrNegative: positiveOrNegativeNumber === 1.00 ? "+" : "-",
      positiveOrNegativeChange: positiveOrNegativeNumberChange === 1.00 ? "+" : "-",
      positiveOrNegativePercent: positiveOrNegativeNumberPercent === 1.00 ? "+" : "-"
    }

    setTwIndex50(resTw50);
    setCgIndex100(resCg100);

  }

  const renderStat = () => {
    if (taiwanStat.length === 0) {
      return (
        <tr className="text-center">
          <td colSpan={7}>No Statistics</td>
        </tr>
      )
    }

    const stat = [];

    for (let i = 0; i < taiwanStat.length; i++) {
      const element = (
        <tr>
          <td>{moment(taiwanStat[i].dateAnnounce).format("DD/MM/YYYY")}</td>
          <td>{taiwanStat[i].up3}</td>
          <td>{taiwanStat[i].down2}</td>
        </tr>
      );

      stat.push(element);
    }

    return stat;
  }

  const taiwanData = async () => {
    if (moment().toDate().getTime() < moment(announceDate).toDate().getTime()) {
      // show null
      return setTaiwanCurrent(null);
    }

    if (moment().toDate().getTime() >= moment(announceDate).toDate().getTime()) {
      // show release
      if (!taiwanCurrent) {
        const current = await services.getCurrentPeriods(20);
        return setTaiwanCurrent(current.data);
      }
    }
  }

  const taiwanClosed = () => {
    return (
      <>
        <td>Evening Closed</td>
        <td>Up</td>
        <td>{taiwanCurrent?.up3}</td>
        <td>Down</td>
        <td>{taiwanCurrent?.down2}</td>
      </>
    )
  }

  return (
    <div className="container p-0">
      <div className="twindex">
        <div className="header">
          <img src={taiwanHeader} style={{ width: '100%', padding: 0 }} />
        </div>

        <div className="content">
          <div className="index-head">Index Charts</div>
          <div className="index-cont">
            <div className="row m-0">
              <div className="col-3 col-left p-0">
                <ul>
                  <li className="taiex active">TAIEX</li>
                  <li className="info">Main Index Info.</li>
                  <li className="tw50">
                    <strong>FTSE TWSE Taiwan 50 Index</strong><br />
                    <span className={`${twIndex50.positiveOrNegative === "+" ? "up" : "down"}`}>
                      <span className="p">{utils.numberWithCommas(twIndex50.value.toString())}</span> &nbsp;
                      <span className="c">{twIndex50.positiveOrNegative === "+" ? Math.abs(twIndex50.change).toFixed(2) : -Math.abs(twIndex50.change).toFixed(2)} ({twIndex50.positiveOrNegative === "+" ? Math.abs(twIndex50.changePercent).toFixed(2) : -Math.abs(twIndex50.changePercent).toFixed(2)}%)</span><br />
                      {moment().format("MMM DD, YYYY HH:mm")}
                    </span></li>
                  <li className="cg100">
                    <strong>TWSE CG 100 Index</strong><br />
                    <span className={`${cgIndex100.positiveOrNegative === "+" ? "up" : "down"}`}>
                      <span className="p">{utils.numberWithCommas(cgIndex100.value.toString())}</span> &nbsp;
                      <span className="c">{cgIndex100.positiveOrNegative === "+" ? Math.abs(cgIndex100.change).toFixed(2) : -Math.abs(cgIndex100.change).toFixed(2)} ({cgIndex100.positiveOrNegative === "+" ? Math.abs(cgIndex100.changePercent).toFixed(2) : -Math.abs(cgIndex100.changePercent).toFixed(2)}%)</span><br />
                      {moment().format("MMM DD, YYYY HH:mm")}
                    </span></li>
                </ul>
              </div>
              <div className="col-9 col-right">
                <div className="tabs">
                  <ul>
                    <li className="active">Real Time</li>
                    <li>Week</li>
                    <li>Month</li>
                    <li>Quarter</li>
                    <li>Year</li>
                  </ul>
                </div>
                <div className="chart">
                  <img src={graph} height={180} width="100%" />
                </div>
                <div className="chart-info text-center">
                  <label className="t">{moment().format("YYYY/MM/DD")}</label>
                  <label className="TWSE_TXT1">TAIEX:</label>
                  <label className="i">{Math.abs(upNumber).toFixed(2)}</label>
                  <label className={`d ${positiveOrNegativeChange === '+' ? 'up' : 'down'}`}>{positiveOrNegativeChange === "+" ? '' : '-'}{Math.abs(downNumber).toFixed(2)}</label>
                  <label className={`p ${positiveOrNegativePercent === '+' ? 'up' : 'down'}`}>{positiveOrNegativePercent === "+" ? '' : '-'}{`${Math.abs(changedPercent).toFixed(2)}%`}</label>
                  <label className="VALUE_TXT">Value</label>
                  <label className="tv ml-1">{volumn10Mil.toFixed(2)} (100 million NTD)</label>
                  <br />
                  <label className="note" style={{ position: 'relative', top: 5 }}>Odd-lot, Block, Off-hour, Auction and Tender Offer trading are not included in Trade Volume.</label>
                </div>
                <div className="today-result">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        {taiwanClosed()}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="history">
        <div className="history-content">
          <h4>Statistics</h4>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th rowSpan={2}>Date</th>
                <th colSpan={2}>Evening Closed</th>
              </tr>
              <tr>
                <th>Up</th>
                <th>Down</th>
              </tr>
            </thead>
            <tbody>
              {renderStat()}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
}

export default App;
